import React, { useState, useEffect } from "react";
import { Box, Drawer, List } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";

import SideBarMenuList from "./SideBarMenuList";
import SubMenuList from "./SubMenuList";

import { dashboardRoute, supplierUploadRoute } from "../../Routes/constants";
import { logout, setting, sideBarList } from "../../constant/sideBarList";

import sotiraLogo from "../../assets/images/logo.png";

import { CustomAppBar, ProfileImage, AvatarImg, DrawerHeader } from "./styles";
import GetUser from "../../Utils/Get-user";
import axios from "axios";
import { toast } from "react-toastify";

interface AuthLayoutProp {
  children: any;
}

const AuthLayout: React.FC<AuthLayoutProp> = ({ children }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const userID = GetUser();
  const [numNoti, setNumNoti] = useState(0);

  const firstName = localStorage.getItem("FirstName");
  const lastName = localStorage.getItem("LastName");

  

  useEffect(() => {
    async function getNotification() {
      try {
        const response = await axios.get(process.env.REACT_APP_API_BASE_URL + 'user/get_noti', { params: { userID: userID } });
        console.log(response);
        setNumNoti(response.data.unseen);
        const prevNumNoti = localStorage.getItem("notifications") || 0;
        if(prevNumNoti && prevNumNoti < response.data.notifications.length && response.data.unseen > 0) {
          toast.success("You have a new notification", {
            position: "top-right",
            autoClose: 3000,
          });
        }
        localStorage.setItem("notifications", response.data.notifications.length);
      } catch (e) {
        // Logout the user and redirect to the login page
      }
    }

    const intervalId = setInterval(() => {
      getNotification()
    }, 1000 * 10) // in milliseconds
    return () => clearInterval(intervalId)
  }, [])

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CustomAppBar isOpen={isOpen} position="fixed">
          <Box>
            {!isOpen && (
              <Box sx={{ gap: "25px", cursor: "pointer" }}>
                <MenuIcon
                  sx={{ width: "34px", height: "34px" }}
                  onClick={() => setIsOpen(true)}
                />
                <img
                  alt="logo"
                  src={sotiraLogo}
                  className="sotira-logo"
                  onClick={() => navigate(supplierUploadRoute)}
                />
              </Box>
            )}
          </Box>
          <Box>
            <ProfileImage>
              <span className="profile-text">
                {firstName} {lastName}
              </span>
              {""}
              <AvatarImg>
                {firstName?.[0]}
                {lastName?.[0]}
              </AvatarImg>
            </ProfileImage>
          </Box>
        </CustomAppBar>
        <Drawer
          sx={{
            width: isOpen ? "300px" : "unset",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: "300px",
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={isOpen}
        >
          <DrawerHeader>
            <Box sx={{ gap: "15px", padding: "20px", cursor: "pointer" }}>
              <MenuIcon
                sx={{ width: "34px", height: "40px" }}
                onClick={() => setIsOpen(false)}
              />
              <img
                alt="logo"
                src={sotiraLogo}
                onClick={() => navigate(supplierUploadRoute)}
              />
            </Box>
          </DrawerHeader>
          <List sx={{ position: "relative", height: "calc(100vh - 64px)" }}>
            <SideBarMenuList menuItem={sideBarList} />
            <div
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
              }}
            >
              <SubMenuList subList={setting} />
              <SubMenuList subList={logout} />
            </div>
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: "calc(100% - 299px)",
            height: "100vh",
            padding: "16px",
          }}
        >
          <DrawerHeader />
          <div style={{ height: "100%" }}>{children}</div>
        </Box>
      </Box>
    </>
  );
};

export default AuthLayout;
