import { useNavigate } from "react-router-dom";
import GetUser from "../../Utils/Get-user";

import { useGetExcessId } from "../../Redux/api/User";

import { dataprocessingRoutes, dataUploadRoute } from "../../Routes/constants";

import {
  DataPreviewBox,
  DataPreviewText,
  ContentText,
  NextButton,
} from "./styles";

const Dashboard = () => {
  const navigate = useNavigate();
  const { data: { data: { excess = [] } = {} } = {} } = useGetExcessId(
    GetUser()
  );

  const latestDateObject = excess?.find((excessdata: any) => {
    return !excessdata?.fileName;
  });

  const handleQuestion = () => {
    if (!!latestDateObject) {
      if (!!latestDateObject?.fileName) {
        navigate(dataprocessingRoutes);
      } else {
        navigate(dataUploadRoute);
      }
    } else {
      navigate(dataprocessingRoutes);
    }
  };
  return (
    <>
      <DataPreviewBox>
        <div>
          <DataPreviewText>Start</DataPreviewText>
          <ContentText>
            We will next ask you some questions about your goals to customize
            the platform for you.
          </ContentText>
          <NextButton onClick={handleQuestion}>Next</NextButton>
        </div>
      </DataPreviewBox>
    </>
  );
};

export default Dashboard;
