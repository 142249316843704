//Public Routes
export const homeRoute = "/";
export const LoginRoute = "/login";
export const RegisterRoute = "/auth/register";
export const BusinessinfoRoute = "/businessinfo";
export const forgotpasswordRoutes = "/auth/forgotPassword";
export const listingRoute = "/listing";
export const offerRoute = "/offer";
export const offerInfoRoute = "/get-info";
export const tosRoute = "/tos";
 
//auth routes
export const dashboardRoute = "/dashboard";
export const dataprocessingRoutes = "/data-process";
export const questionRoute = "/questions";
export const dataUploadRoute = "/dataupload";
export const datapreviewRoute = "/data-preview";
export const logoutRoute = "/logout";
export const settingRoute = "/settings";
export const previousRecommendation = "/previous-recommendation";
export const storageCostRoute = "/storage-cost";
export const confirmationRoutes = "/confirmation";
export const offloadingoptionRoutes = "/offloading-options";
export const additionaloptionRoutes = "/additional-options";
export const buyerDataUploadRoute = '/buyer-search';
export const buyerSearchResultsRoute = '/buyer-results';
export const supplierUploadRoute = '/supplier-upload';
export const trackOffersRoute = '/offers';
export const reviewOffersRoute = '/review-offer';
export const notificationsRoute = '/notifications';
export const reviewOffersInfoRoute = '/review-offer-info';
