import styled from "@emotion/styled";

export const DataPreviewBox = styled("div")`
  max-width: 860px;
  width: 100%;
  min-height: 409px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DataPreviewText = styled("div")`
  color: #171717;
  font-size: 35px;
  font-style: normal;
  font-family: "ProximaNova" !important;
  font-weight: 700;
  line-height: 124%;
  text-align: center;
  margin-bottom: 70px;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

export const ContentText = styled("div")`
  color: #343434;
  font-size: 22px;
  font-style: normal;
  font-family: "ProximaNova-Medium" !important;
  font-weight: 400;
  line-height: 27px;
  margin-bottom: 70px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const NextButton = styled("button")`
  background-color: #48b7a7;
  color: white;
  font-weight: 700;
  line-height: 1.5;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 1px solid;
  padding: 15px 25px;
  border-radius: 5px;
  font-size: 22px;
  font-family: "ProximaNova" !important;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 97px;
  max-height: 55px;
  height: 100%;
  width: 100%;
  cursor: pointer;
  margin: 0 auto;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
