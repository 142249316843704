import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ConfirmationText, NextButton, CheckboxContainer, CheckboxLabel } from "./styles";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import GetUser from "../../Utils/Get-user";

import {ContextHeading, HeadingText, DataPreviewBox, CostText} from './styles';

import LocPopup from "./locationPop";

interface Location {
  _id: any;
  name: any;
  address: any;
  city: any;
  state: any;
  zip: any;
}


export default function Tos(props: any) {
  const logged_user = GetUser();
  const [addLocation, setAddLocation] = useState(false);
  const [openLocModal, setOpenLocModal] = useState(false);
  const [locations, setLocations] = useState<Location[]>([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [primary, setPrimary] = useState('');



  useEffect(() => {
    const fetchData = async () => {
      try {
        fetchLocations();
        
        
      } catch (error) {
        console.error("Error grabbing tos data:", error);
      }
    };
  
    fetchData();
  }, []);

  const fetchLocations = async () => {
    const response = await axios.get(process.env.REACT_APP_API_BASE_URL + 'user/get_all_loc', { params: { userID: logged_user } });
    if(response.data.locations) {
      setLocations(response.data.locations);
      if(response.data.locations.length === 0) {
        setAddLocation(true);
      } else {
        setAddLocation(false);
      }
      return response.data.locations;
    } else {
      return [];
    }
  }

  const handleLocationSelect = (event: any) => {
    const selectedValue = event.target.value;
    console.log(event);
    console.log(selectedValue);
    setSelectedLocation(selectedValue);
    if (selectedValue === 'Add Location') {
      setAddLocation(true);
    } else {
      setAddLocation(false);
    }
  };

  const handleLocOpen = () => {
    setOpenLocModal(true);
  }

  const handleLocClose = () => {
    setOpenLocModal(false);
    const all_locations = fetchLocations();
  };

  const showLocationAction = () => {
    if(addLocation) {
      return( 
        <NextButton onClick={handleLocOpen}>Add New Location</NextButton>
      )
    }
    if(selectedLocation === primary) {
      return (
        <NextButton onClick={handleLocOpen} disabled>Already is primary location</NextButton>
      )
    } else {
      return(
        <NextButton onClick={handleLocOpen}>Set as primary location</NextButton>
      )
    }
  }

  

  const showOptions = () => {
    return(
      <div> <label htmlFor="location-select">Set a primary or add a location:</label>
      <select id="location-select" value={selectedLocation} onChange={handleLocationSelect} style={{
          width: '100%',
          padding: '10px',
          margin: '10px 0',
          borderRadius: '5px',
          border: '1px solid #ccc'
        }}>
        {locations.length === 0 ? (
          <option value="Add Location">Add Location</option>
        ) : (
          <>
            {locations.map((location, index) => (
              <option key={index} value={location?._id}>
                {location?.name} - {location?.address}
              </option>
            ))}
            <option value="Add Location">Add Location</option>
          </>
        )}
      </select>

      {showLocationAction()}
       </div>
    )
  }


  const showLocation = () => { 
    return (
      <>
      <Dialog
             open={openLocModal}
             onClose={handleLocClose}
             maxWidth="md"
             fullWidth
             PaperProps={{
               style: {
                 overflowX: "hidden",
                 maxWidth: "665px",
                 width: "100%",
               },
             }}
           >
             <DialogTitle>
               <IconButton
                 edge="end"
                 color="inherit"
                 onClick={handleLocClose}
                 aria-label="close"
                 sx={{
                   position: "absolute",
                   right: 8,
                   top: 0,
                 }}
               >
                 <CloseIcon />
               </IconButton>
             </DialogTitle>
             <DialogContent>
               <LocPopup onClose={handleLocClose} />
             </DialogContent>
           </Dialog>
      <DataPreviewBox>
        <div> {showOptions()} </div>
      </DataPreviewBox>
      </>
    );
  } 


  return (
    <div>
      <ContextHeading> <HeadingText> Settings </HeadingText> </ContextHeading>
      <CostText> Location Settings </CostText>
      <div> {showLocation()} </div>
    </div>
  )  
}
