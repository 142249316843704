import { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/styles";
import GetUser from "../../Utils/Get-user";
import moment from "moment";
import { getExcessobj } from "../../Utils/getexcessobj";
import { Card, CardContent, CircularProgress } from "@mui/material";

import { useGetExcessId } from "../../Redux/api/User";

import { PreviousRecomandationBox, PreviousRecomandationText, NextButton } from "./styles";

const useStyles = makeStyles(() => ({
  tableContainer: {
    width: "100%",
    borderRadius: "5px !important",
    boxShadow: "0px 3px 15px 5px rgba(0,0,0,.1) !important",
    marginTop: "20px",
  },
  table: {
    backgroundColor: "#fcfcfc",
    borderRadius: "5px !important",
    boxShadow: "0px 3px 15px 5px rgba(0,0,0,.1) !important",
  },
  cell: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#000",
    fontFamily: "ProximaNova !important",
    borderRight: "1px solid #7C7C7C",
    borderTop: "1px solid #7C7C7C",
    borderBottom: "none",
    "&:last-child": {
      borderRight: "none",
    },
  },
  header: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "ProximaNova !important",
    color: "#000",
    backgroundColor: "#f3f0f0",
    borderRight: "1px solid #7C7C7C",
    borderBottom: "1px solid #7C7C7C",
    "&:last-child": {
      borderRight: "none",
    },
  },
}));
const Notifications = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const [notis, setNotis] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [listingItem, setListingItem] = useState([]);
  const [madeOffer, setMadeOffer] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [makeOffer, setMakeOffer] = useState(false);
  const [foundPage, setFoundPage] = useState(0);
  const [foundRowsPerPage, setFoundRowsPerPage] = useState(10);
  const [foundPaginatedData, setFoundPaginatedData] = useState<any[]>([]);
  const [orderBy, setOrderBy] = useState("seen");
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  
  var total_cost = 0;
  var tax_rate = 0;

  var params = {}
  const expDateCheck = true;

  const currDate = new Date();
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_BASE_URL + 'user/get_noti', { params: { userID: GetUser()} });
        setNotis(response.data.notifications);
        setMadeOffer(response.data.offerFound);
        setIsLoading(false);
        
      } catch (error) {
        console.error("Error grabbing listing:", error);
      }
    };
  
    fetchData();
  }, []);

  useEffect(() => {
    const startFoundIndex = foundPage * foundRowsPerPage;
    const endFoundIndex = startFoundIndex + foundRowsPerPage;
    setFoundPaginatedData(notis.slice(startFoundIndex, endFoundIndex));
  }, [notis, foundPage, foundRowsPerPage]);


  const handleFoundChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setFoundPage(newPage);
  };

  const handleFoundChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFoundRowsPerPage(parseInt(event.target.value, 10));
    setFoundPage(0);
  };

  const handleSeeOffer = (id: string) => {
    
    navigate('/review-offer?o=' + id)
  }
  console.log(notis);

  const sortedData = notis.sort((a, b) => {
    if (orderBy) {
      if (order === "asc") {
        return a[orderBy] < b[orderBy] ? -1 : 1;
      }
      return a[orderBy] > b[orderBy] ? -1 : 1;
    }
    return 0;
  });

  const handleRequestSort = (property: string) => (event: React.MouseEvent<unknown>) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const showMessage = (message: string) => {
    if (message.length <= 0) {
      return (<span> </span>)
    } else {
      return (<span> {message} </span>)
    }
  }

  const showAmount = (amount: any, status: any) => {
    if(amount.length > 0) {
      const curr_amount = amount[amount.length - 1].toLocaleString(undefined, { minimumFractionDigits: 2 })
      if(status === "REJ") {
        return (<span> Rejected </span>)
      } else {
        return (<span> ${curr_amount}</span>)
      }

    } else {
      return (<span> Pending </span>)
    }
  }

  const showAction = (noti: any) => {
    console.log(noti);
    if(noti.offerId) {
      return (
        <NextButton onClick={() => handleSeeOffer(noti.offerId)}> See Full Offer </NextButton>
      )
    } else {
      return (
        <NextButton disabled> No action </NextButton>
      )
    }
  }

  return (
    <>
    <PreviousRecomandationBox>
        <PreviousRecomandationText>
          Notifications{" "}
        </PreviousRecomandationText>
        {isLoading ? (
          <Card className="mt-2 p-0" sx={{ boxShadow: 0 }}>
            <CardContent className="pt-1 d-flex justify-content-center">
              <CircularProgress sx={{ color: "#41c175" }} />
            </CardContent>
          </Card>
        ) : (
          <>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" className={classes.header}>
                    Notification
                  </TableCell>
                  <TableCell align="center" className={classes.header} sortDirection={orderBy === "dateStart" ? order : false}>
                    Date
                  </TableCell>
                  <TableCell align="center" className={classes.header}>
                    Actions
                  </TableCell>
                  {/** 
                  <TableCell align="center" className={classes.header}>
                    Listings
                  </TableCell>
                  */}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData.slice(foundPage * foundRowsPerPage, foundPage * foundRowsPerPage + foundRowsPerPage)?.map((noti: any, index: Number) => (
                  <TableRow>
                    <TableCell align="center" className={classes.cell}>
                      {showMessage(noti.message)}
                    </TableCell>
                    <TableCell align="center" className={classes.cell}>
                      {moment(noti.date).format("MM/DD/YYYY")}
                    </TableCell>
                    <TableCell align="center" className={classes.cell}>
                      {showAction(noti)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={notis.length}
          rowsPerPage={foundRowsPerPage}
          page={foundPage}
          onPageChange={handleFoundChangePage}
          onRowsPerPageChange={handleFoundChangeRowsPerPage}
          sx={{
            '& .MuiTablePagination-displayedRows': {
              marginBottom: '0rem', 
            },
            '& .MuiTablePagination-selectLabel': {
              marginBottom: '0rem',
            },
          }}
        />
        </>
          
        )}
        </PreviousRecomandationBox>
    </>
  );
};
export default Notifications;
