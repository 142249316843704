import React, { useState } from "react";
import { Box, Drawer, List } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";


import { homeRoute } from "../../Routes/constants";
import { logout, setting, sideBarList } from "../../constant/sideBarList";

import sotiraLogo from "../../assets/images/logo.png";

import { CustomAppBar, ProfileImage, AvatarImg, DrawerHeader } from "./styles";
interface AuthLayoutProp {
  children: any;
}

const PubLayout: React.FC<AuthLayoutProp> = ({ children }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CustomAppBar isOpen={isOpen} position="fixed">
          <Box>
            {!isOpen && (
              <Box sx={{ gap: "25px", cursor: "pointer" }}>
                <img
                  alt="logo"
                  src={sotiraLogo}
                  className="sotira-logo"
                  onClick={() => navigate(homeRoute)}
                />
              </Box>
            )}
          </Box>
          <Box>
            <ProfileImage>

              {""}
              <AvatarImg>
              </AvatarImg>
            </ProfileImage>
          </Box>
        </CustomAppBar>
        <Drawer
          sx={{
            width: isOpen ? "300px" : "unset",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: "300px",
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={isOpen}
        >
          <DrawerHeader>
            <Box sx={{ gap: "15px", padding: "20px", cursor: "pointer" }}>
              <MenuIcon
                sx={{ width: "34px", height: "40px" }}
                onClick={() => setIsOpen(false)}
              />
              <img
                alt="logo"
                src={sotiraLogo}
                onClick={() => navigate(homeRoute)}
              />
            </Box>
          </DrawerHeader>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: "calc(100% - 299px)",
            height: "100vh",
            padding: "16px",
          }}
        >
          <DrawerHeader />
          <div style={{ height: "100%" }}>{children}</div>
        </Box>
      </Box>
    </>
  );
};

export default PubLayout;
